.header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Pushes logo and nav to opposite ends */
    background-color: #ffffff; /* Dark background for the header */
    padding: 5px 30px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.823); /* Shadow at the bottom */
}

.logo {
    height: 70px;
}

@media (max-width: 768px) {
    .logo {
        height: 70px;
        padding-top: 10px;
    }
}

nav {
    display: flex;
    justify-content: flex-end; /* Aligns links to the right */
    align-items: center;
    gap: 20px; /* Space between links */
}

.header-link {
    font-family: 'Raleway', sans-serif;
    text-decoration: none;
    color: #202020; /* Light text color for visibility */
    background-color: transparent; /* No background for regular links */
    padding: 10px 15px;
    transition: color 0.3s;
    text-transform: uppercase;
    font-weight: bold;
}

.header-link:hover {
    color: #d80f0f; /* Highlight color on hover */
}

.header-link.special {
    background-color: #ffbd07; /* Distinct background for the special button */
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 2px; /* Rounded corners */
}

.header-link.special:hover {
    background-color: #c64727; /* Darker color on hover */
}

/* Existing styles... */

@media (max-width: 768px) {
    .nav-links {
        display: none;
        flex-direction: column;
        width: 100%; /* Full width */
        align-items: center;
        position: absolute; /* Positioning it over other content */
        background-color: #212121; /* Same as header background */
        top: 80px; /* Below the header */
        left: 0;
        right: 0;
        padding: 20px 0;
        z-index: 100;
    }

    .nav-links.open {
        display: flex; /* This will show the navigation links */
    }

    .menu-icon {
        display: block; /* Show menu icon on mobile */
        cursor: pointer;
        color: #000000;
        font-size: 24px;
        padding: 10px;
    }
}

@media (min-width: 769px) {
    .menu-icon {
        display: none; /* Hide menu icon on desktop */
    }
}